import React from "react";
import styled from "styled-components";

import video1 from "../../assets/videos/Intro_Vid_01.mp4";
import video2 from "../../assets/videos/1. Mindful Being.mp4";
import video3 from "../../assets/videos/2. Resillience.mp4";
import video4 from "../../assets/videos/3. Healthy Body.mp4";
import video5 from "../../assets/videos/4. Purposeful Achievement.mp4";
import video6 from "../../assets/videos/5. Positive Relationships.mp4";
import video7 from "../../assets/videos/6. LastingLove.mp4";
import video8 from "../../assets/videos/7. Strong Families.mp4";

const videoRef = [
  video1,
  video2,
  video3,
  video4,
  video5,
  video6,
  video7,
  video8,
];

const VideoContainer = styled.div`
  max-width: 90vw;
  border-radius: 1px;

  @media only screen and (min-width: 900px) {
    min-height: 397px;
    background-color: #f5f5f5;
    min-width: 700px;
  }
`;

const VideoTag = styled.video`
  max-width: 90vw;
  min-width: 90vw;
  min-height: 51vw;
  border-radius: 1px;

  @media only screen and (min-width: 900px) {
    width: 700px;
    min-width: 700px;
    min-height: 397px;
  }
`;

const Video = (props) => {
  const { videoNumber } = props;
  return (
    <VideoContainer>
      <VideoTag
        src={videoRef[videoNumber]}
        type="video/mp4"
        controls
        playsInline
      ></VideoTag>
    </VideoContainer>
  );
};

export default Video;
