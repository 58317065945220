import React from "react";
import styled from "styled-components";

import homepageModel from "../../assets/images/homepage-model.jpg";

const Model = styled.img`
  width: 28vw;
  margin-bottom: 26px;
  min-width: 280px;
  max-width: 509px;
  @media only screen and (min-width: 400px) {
    min-width: 300px;
  }
  @media only screen and (min-width: 900px) {
    min-width: 350px;
  }
`;

const HomePageModel = () => {
  return <Model src={homepageModel} alt="Resilient Life Model" />;
};

export default HomePageModel;
