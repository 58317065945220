import React from "react";
import styled from "styled-components";

import homepageModel from "../../assets/images/homepage-model.jpg";

const Model = styled.img`
  width: 51%;
  padding-left: 10px;
`;

const HomePageModel = () => {
  return <Model src={homepageModel} alt="Resilient Life Model" />;
};

export default HomePageModel;
