import React from "react";
import styled from "styled-components";

const Button = styled.button`
  color: #fff;
  background-color: #6ee4b4;
  padding: 15px 30px;
  font-size: 17px;
  font-weight: 600;
  border: none;
  margin-top: 20px;
  border-radius: 1px;
  cursor: pointer;
  max-width: 90vw;
  @media only screen and (max-width: 900px) {
    margin-top: 50px;
  }
`;

const HomePageButton = (props) => {
  const { toggleModal } = props.props;
  return (
    <Button onClick={toggleModal}>
      CURIOUS?... WE'D LOVE TO HEAR FROM YOU
    </Button>
  );
};

export default HomePageButton;
