import React from "react";
import styled from "styled-components";

import v1System from "../../assets/images/icons/videoIcons/v1System.jpg";
import v2Mind from "../../assets/images/icons/videoIcons/v2Mind.jpg";
import v3Resil from "../../assets/images/icons/videoIcons/v3Resil.jpg";
import v4Health from "../../assets/images/icons/videoIcons/v4Health.jpg";
import v5Achieve from "../../assets/images/icons/videoIcons/v5Achieve.jpg";
import v6Relate from "../../assets/images/icons/videoIcons/v6Relate.jpg";
import v7Love from "../../assets/images/icons/videoIcons/v7Love.jpg";
import v8Family from "../../assets/images/icons/videoIcons/v8Family.jpg";

const titles = [
  {
    title: "WHY THE SYSTEM WAS CREATED",
    duration: "3 minutes 50 seconds",
    icon: v1System,
  },
  {
    title: "THE POWER OF MINDFUL BEING",
    duration: "Duration: 45 seconds",
    icon: v2Mind,
  },
  {
    title: "RESILIENCE",
    duration: "Duration: 45 seconds",
    icon: v3Resil,
  },
  {
    title: "PHYSICAL HEALTH",
    duration: "Duration: 45 seconds",
    icon: v4Health,
  },
  {
    title: "PURPOSEFUL ACHIEVEMENT",
    duration: "Duration: 45 seconds",
    icon: v5Achieve,
  },
  {
    title: "POSITIVE RELATIONSHIPS",
    duration: "Duration: 45 seconds",
    icon: v6Relate,
  },
  {
    title: "LASTING LOVE",
    duration: "Duration: 45 seconds",
    icon: v7Love,
  },
  {
    title: "STRONG FAMILIES",
    duration: "Duration: 45 seconds",
    icon: v8Family,
  },
];

const SubtitleContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: -60px;
  height: 60px;
  @media only screen and (min-width: 900px) and (max-height: 680px) {
    bottom: -50px;
  }
`;

const Icon = styled.img`
  max-height: 55px;
  max-width: 55px;
  padding-right: 10px;
  @media only screen and (min-width: 900px) and (max-height: 680px) {
    max-height: 45px;
    max-width: 45px;
  }
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: 600;
  color: #a4abaf;
  margin: 0;
  @media only screen and (min-width: 400px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 900px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 900px) and (max-height: 680px) {
    font-size: 15px;
  }
`;

const Duration = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: justify;
  color: #9ba1a4;
  line-height: 1.5;
  margin: 5px 0 0;
  @media only screen and (min-width: 400px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 900px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 900px) and (max-height: 680px) {
    font-size: 13px;
  }
`;

const Subtitle = (props) => {
  const { subtitleNumber } = props;
  const { title, duration, icon } = titles[subtitleNumber];
  return (
    <SubtitleContainer>
      <Icon
        src={icon}
        alt={title}
        style={{
          left:
            subtitleNumber === 6
              ? "-88px"
              : subtitleNumber === 4
              ? "-50px"
              : undefined,
          top: subtitleNumber === 4 ? "2px" : undefined,
        }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Title>{title}</Title>
        <Duration>{duration}</Duration>
      </div>
    </SubtitleContainer>
  );
};

export default Subtitle;
