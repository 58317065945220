import React from "react";
import styled from "styled-components";

import HomePageModel from "./homepage-elements/HomePageModel";
import HomePageButton from "./homepage-elements/HomePageButton";

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 97px);
  margin-top: 97px;
`;

const HomePageSection = (props) => {
  return (
    <HomePageContainer id="home">
      <HomePageModel />
      <HomePageButton props={props} />
    </HomePageContainer>
  );
};

export default HomePageSection;
