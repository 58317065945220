import React from "react";
import styled from "styled-components";

const Button = styled.button`
  color: #fff;
  background-color: #6ee4b4;
  padding: 15px 30px;
  font-size: 17px;
  font-weight: 600;
  border: none;
  margin-top: 20px;
  border-radius: 1px;
  cursor: pointer;
`;

const TestimonialButton = (props) => {
  const { toggleModal } = props.props;
  return (
    <Button id="button" onClick={toggleModal}>
      STILL CURIOUS?... LET'S TALK
    </Button>
  );
};

export default TestimonialButton;
