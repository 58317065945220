import React from "react";
import styled from "styled-components";

import closeQuoteIcon from "../../assets/images/icons/close-quote.jpg";

const Quote = styled.img`
  height: 56px;
  align-self: flex-end;
  margin-bottom: 0;
  @media only screen and (min-width: 400px) {
    height: 60px;
  }
  @media only screen and (min-width: 900px) {
    height: 80px;
  }
  @media only screen and (min-width: 1024px) {
    position: absolute;
    right: -164px;
    margin-top: 180px;
  }
`;

const CloseQuote = () => {
  return <Quote src={closeQuoteIcon} />;
};

export default CloseQuote;
