import React from "react";
import styled from "styled-components";

import openQuote from "../../assets/images/icons/open-quote-b.jpg";
import closeQuote from "../../assets/images/icons/close-quote-b.jpg";

const TestimonialContainer = styled.div`
  height: 285px;
  min-width: 250px;
  margin: 0 20px;
  padding: 10px 10px 0;
  text-align: center;
  position: relative;
`;

const Quote = styled.p`
  padding-top: 45px;
  line-height: 1.6;
  text-align: justify;
  font-size: 16px;
`;

const Name = styled.h5`
  font-size: 21px;
  position: absolute;
  bottom: 14px;
  right: 7px;
  margin: 0;
`;

const OpenQuote = styled.img`
  max-height: 48px;
  position: absolute;
  left: 7px;
  top: 9px;
`;

const CloseQuote = styled.img`
  max-height: 50px;
  position: absolute;
  right: 7px;
  bottom: 43px;
`;

const Testimonial = (props) => {
  const { styling, testimonialNumber, testimonials } = props;
  const { color, backgroundColor, nameColor, hasQuotes } = styling;
  const { quote, name } = testimonials[testimonialNumber];

  return (
    <TestimonialContainer style={{ backgroundColor }}>
      {hasQuotes && <OpenQuote src={openQuote} />}
      <Quote style={{ color }}>{quote}</Quote>
      {hasQuotes && <CloseQuote src={closeQuote} />}
      <Name style={{ color: nameColor }}>{name}</Name>
    </TestimonialContainer>
  );
};

export default Testimonial;
