import React, { useState } from "react";
import styled from "styled-components";

import Testimonial from "./testimonial-elements/Testimonial";
import TestimonialButton from "./testimonial-elements/TestimonialButton";

import testimonials from "../assets/copy/testimonials.json";
import prevArrow from "../assets/images/icons/arrow-prev.jpg";
import nextArrow from "../assets/images/icons/arrow-next.jpg";

const TestimonialSectionContainer = styled.div`
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 66vh;
  margin-top: 97px;
`;

const TestimonialTitle = styled.h2`
  font-weight: 100;
  color: #a6aeb2;
  margin: 0 0 10px;
  text-align: center;
  font-size: 34px;
`;

const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TestimonialTitleContainer = styled.div`
  display: flex;
`;

const TestimonialCarousel = styled.div`
  display: flex;
  max-width: 1200px;
  overflow-x: hidden;
  justify-content: center;
`;

const Arrow = styled.img`
  cursor: pointer;
  max-height: 23px;
  margin: 7px 30px 0;
`;

const calculateIndex = (count, testimonialCount) => {
  let index;

  if (count <= testimonialCount && count >= 0) {
    index = count;
  } else if (count < 0) {
    index = testimonialCount + count + 1;
  } else {
    index = count - testimonialCount - 1;
  }

  return index;
};

const TestimonialSection = (props) => {
  const testimonialCount = testimonials.length - 1;
  const [count, setCount] = useState(0);

  const sideStyling = {
    color: "#e2e3e5",
    backgroundColor: "#fff",
    nameColor: "#e0e1e3",
  };

  const centerStyling = {
    color: "#000",
    backgroundColor: "#e3e6e7",
    nameColor: "#a5acaf",
    hasQuotes: true,
  };

  return (
    <TestimonialSectionContainer id="testimonials">
      <TestimonialContainer>
        <TestimonialTitleContainer>
          <Arrow
            src={prevArrow}
            alt="Previous Testimonial"
            onClick={() => {
              const nextValue = count - 1 >= 0 ? count - 1 : testimonialCount;
              setCount(nextValue);
            }}
          />
          <TestimonialTitle>Testimonials</TestimonialTitle>
          <Arrow
            src={nextArrow}
            alt="Next Testimonial"
            onClick={() => {
              const nextValue = count + 1 <= testimonialCount ? count + 1 : 0;
              setCount(nextValue);
            }}
          />
        </TestimonialTitleContainer>
        <TestimonialCarousel>
          <Testimonial
            testimonialNumber={calculateIndex(count - 2, testimonialCount)}
            styling={sideStyling}
            testimonials={testimonials}
          ></Testimonial>
          <Testimonial
            testimonialNumber={calculateIndex(count - 1, testimonialCount)}
            styling={sideStyling}
            testimonials={testimonials}
          ></Testimonial>
          <Testimonial
            testimonialNumber={calculateIndex(count, testimonialCount)}
            styling={centerStyling}
            testimonials={testimonials}
          ></Testimonial>
          <Testimonial
            testimonialNumber={calculateIndex(count + 1, testimonialCount)}
            styling={sideStyling}
            testimonials={testimonials}
          ></Testimonial>
          <Testimonial
            testimonialNumber={calculateIndex(count + 2, testimonialCount)}
            styling={sideStyling}
            testimonials={testimonials}
          ></Testimonial>
        </TestimonialCarousel>
      </TestimonialContainer>
      <TestimonialButton props={props} />
    </TestimonialSectionContainer>
  );
};

export default TestimonialSection;
