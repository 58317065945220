import React from "react";
import styled from "styled-components";

import contactImage from "../assets/images/contact-image.jpg";

import ContactModel from "./contact-elements/ContactModel";
import ContactButton from "./contact-elements/ContactButton";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 97px);
  margin-top: 97px;
`;

const ImageContainer = styled.div`
  max-height: 40vw;
  max-width: 78vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 900px) {
    max-width: 60vw;
    max-height: 30vw;
  }
`;

const ContactImage = styled.img`
  width: 48%;
`;

const ButtonContainer = styled.div`
  max-width: 45vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }

  @media only screen and (min-width: 900px) and (max-height: 730px) {
    flex-direction: row;
  }
`;

const Quote = styled.p`
  font-size: 13px;
  font-weight: 400;
  text-align: justify;
  color: #9ba1a4;
  line-height: 1.8;

  @media only screen and (max-width: 600px) {
    line-height: 1.4;
  }

  @media only screen and (min-width: 400px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 900px) {
    font-size: 18px;
  }

  @media only screen and (max-height: 730px) {
    font-size: 13px;
  }
`;

const InnerContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 78vw;
  @media only screen and (min-width: 900px) {
    max-width: 45vw;
  }
`;

const ContactSection = (props) => {
  return (
    <ContactContainer id="contact">
      <InnerContactContainer>
        <ImageContainer>
          <ContactImage src={contactImage} />
          <ContactModel />
        </ImageContainer>
        <Quote>
          <span style={{ fontWeight: "bold" }}>
            How can we tap the river of well-being, health and satisfaction we
            all desire?
          </span>{" "}
          With nearly two decades of professional experience in helping talented
          people lead impeccable lives, Ricky has created a world leading system
          to transform notoriously problematic issues in modern societies. This
          led to the notion of 7 key areas of self mastery and the Resilient
          Life System.
        </Quote>
      </InnerContactContainer>
      <ButtonContainer>
        <ContactButton
          message="SCHEDULE A FREE CALL"
          toggleModal={props.toggleModal}
        />
      </ButtonContainer>
    </ContactContainer>
  );
};

export default ContactSection;
