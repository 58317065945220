import React from "react";
import styled from "styled-components";

const Button = styled.button`
  color: #fff;
  background-color: #6ee4b4;
  padding: 15px 0;
  font-size: 13px;
  font-weight: 900;
  border: none;
  margin: 5px 0;
  border-radius: 1px;
  min-width: 290px;

  @media only screen and (min-width: 400px) {
    font-size: 15px;
  }

  @media only screen and (min-width: 900px) {
    font-size: 17px;
    min-width: 314px;
    margin: 20px;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 15px;
    min-width: 289px;
    margin: 0 5px;
  }

  @media only screen and (min-width: 900px) and (max-height: 680px) {
    font-size: 13px;
    min-width: 260px;
  }
`;

const ContactButton = (props) => {
  const { message, link, toggleModal } = props;
  const toLink = () => (document.location.href = link);
  return <Button onClick={toggleModal || toLink}>{message}</Button>;
};

export default ContactButton;
