import React from "react";
import styled from "styled-components";

const PageSection = styled.div`
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  max-width: 97vw;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageTemplate = (props) => {
  return <PageSection>{props.children}</PageSection>;
};

export default PageTemplate;
