import React from "react";
import styled from "styled-components";

import NavMenu from "./header-elements/NavMenu";
import MainLogo from "./header-elements/MainLogo";
import SocialButtons from "./header-elements/SocialButtons";

const HeaderContainer = styled.div`
  width: calc(100% - 10px);
  padding: 10px 5px;
  background-color: #fff;
  box-shadow: 0 11px 7px -7px #e0e0e0;
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const HeaderElements = styled.div`
  width: 95%;
  display: flex;
  padding-top: 3px;
  justify-content: space-between;
`;

const SubHeading = styled.h3`
  margin: 14px 0 4px;
  font-weight: 400;
  font-size: 13px;
  color: #a6aeb2;
  @media only screen and (min-width: 400px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 900px) {
    font-size: 16px;
  }
`;

const Header = (props) => {
  return (
    <HeaderContainer>
      <HeaderElements>
        <NavMenu props={props} />
        <MainLogo />
        <SocialButtons props={props} />
      </HeaderElements>
      <SubHeading>Harness the power of psychological well-being</SubHeading>
    </HeaderContainer>
  );
};

export default Header;
