import React from "react";
import styled from "styled-components";

import menuIcon from "../../assets/images/icons/menu-icon.jpg";
import closeMenuIcon from "../../assets/images/icons/close-menu.png";

const Menu = styled.img`
  height: 19px;
  width: 26px;
  margin-top: 13px;
  cursor: pointer;
`;

const NavMenu = (props) => {
  const { toggleMenu, showMenu } = props.props;
  return (
    <Menu src={showMenu ? closeMenuIcon : menuIcon} onClick={toggleMenu} />
  );
};

export default NavMenu;
