import React from "react";
import styled from "styled-components";

import titleIcon from "../../assets/images/icons/title-icon.jpg";

const MainLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
`;

const Logo = styled.img`
  height: 36px;
  width: 36px;
`;

const Title = styled.h3`
  margin: 3px 0 0;
  font-weight: 400;
  padding: 0 0 2px 6px;
  font-size: 19px;
  color: #a6aeb2;
`;

const MainLogo = () => {
  return (
    <MainLogoContainer>
      <Logo src={titleIcon} alt="Resilient Life Icon" />
      <Title>
        RESILIENT<span style={{ fontWeight: "600" }}>LIFE</span>
      </Title>
    </MainLogoContainer>
  );
};

export default MainLogo;
