import React from "react";
import styled from "styled-components";

import openQuoteIcon from "../../assets/images/icons/open-quote.jpg";

const Quote = styled.img`
  height: 56px;
  align-self: baseline;
  margin-bottom: 20px;
  @media only screen and (min-width: 400px) {
    height: 60px;
  }
  @media only screen and (min-width: 900px) {
    height: 80px;
  }
  @media only screen and (min-width: 1024px) {
    position: absolute;
    left: -164px;
  }
`;

const OpenQuote = () => {
  return <Quote src={openQuoteIcon} />;
};

export default OpenQuote;
