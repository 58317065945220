import React, { useState } from "react";
import styled from "styled-components";

import Video from "./video-elements/Video";
import Subtitle from "./video-elements/Subtitle";

import prevArrow from "../assets/images/icons/arrow-prev.jpg";
import nextArrow from "../assets/images/icons/arrow-next.jpg";

const VideoSectionContainer = styled.div`
  color: #333;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 97px);
  margin-top: 97px;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95vw;
  position: relative;
  @media only screen and (min-width: 900px) and (max-height: 680px) {
    bottom: 46px;
  }
`;

const ArrowL = styled.img`
  cursor: pointer;
  max-height: 21px;
  position: absolute;
  top: -39px;
  left: 12px;

  @media only screen and (min-width: 900px) {
    max-height: 25px;
    position: relative;
    left: -410px;
    top: 216px;
  }
`;

const ArrowR = styled.img`
  cursor: pointer;
  max-height: 25px;
  position: absolute;
  top: -39px;
  right: 12px;

  @media only screen and (min-width: 900px) {
    position: relative;
    max-height: 25px;
    right: -410px;
    top: -269px;
  }
`;

const VideoSection = () => {
  const videoCount = 7;
  const [count, setCount] = useState(0);

  return (
    <VideoSectionContainer id="system">
      <VideoContainer>
        <ArrowL
          src={prevArrow}
          alt="Previous Video"
          onClick={() => {
            const nextValue = count - 1 >= 0 ? count - 1 : videoCount;
            setCount(nextValue);
          }}
        />
        <Video videoNumber={count}></Video>
        <Subtitle subtitleNumber={count}></Subtitle>
        <ArrowR
          src={nextArrow}
          alt="Next Video"
          onClick={() => {
            const nextValue = count + 1 <= videoCount ? count + 1 : 0;
            setCount(nextValue);
          }}
        />
      </VideoContainer>
    </VideoSectionContainer>
  );
};

export default VideoSection;
