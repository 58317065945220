import React from "react";
import styled from "styled-components";

const Menu = styled.div`
  background: #fff;
  position: fixed;
  top: 48px;
  left: 43px;
  z-index: 1000;
  border: 1px solid #555;
  padding-right: 13px;
  border-radius: 2px;
`;

const NavItem = styled.li`
  list-style-type: none;
  padding: 20px 20px 20px 0;
`;

const NavAnchor = styled.a`
  text-decoration: none;
  color: #555;
  padding: 10px;
`;

const NavMenuOptions = () => {
  return (
    <Menu>
      <ul>
        <NavItem>
          <NavAnchor href="#home">home</NavAnchor>
        </NavItem>
        <NavItem>
          <NavAnchor href="#mission">mission</NavAnchor>
        </NavItem>
        <NavItem>
          <NavAnchor href="#testimonials">testimonials</NavAnchor>
        </NavItem>
        <NavItem>
          <NavAnchor href="#system">system</NavAnchor>
        </NavItem>
        <NavItem>
          <NavAnchor href="#contact">contact</NavAnchor>
        </NavItem>
      </ul>
    </Menu>
  );
};

export default NavMenuOptions;
