import React, { useState } from "react";

import Header from "./components/Header";
import SubscribeModal from "./components/SubscribeModal";
import PageTemplate from "./components/PageTemplate";
import HomePageSection from "./components/HomePageSection";
import QuoteSection from "./components/QuoteSection";
import VideoSection from "./components/VideoSection";
import TestamonialSection from "./components/TestamonialSection";
import ContactSection from "./components/ContactSection";
import NavMenuOptions from "./components/header-elements/NavMenuOptions";

const App = () => {
  const [showModal, updateModal] = useState(false);
  const [showMenu, updateMenu] = useState(false);

  const toggleMenu = () => {
    updateMenu(!showMenu);
  };

  const toggleModal = (e) => {
    e.preventDefault();
    updateModal(!showModal);
  };

  return (
    <div className="App">
      {showMenu && <NavMenuOptions />}
      <Header
        showMenu={showMenu}
        toggleModal={toggleModal}
        toggleMenu={toggleMenu}
      />
      {showModal && <SubscribeModal toggleModal={toggleModal} />}
      <PageTemplate>
        <HomePageSection toggleModal={toggleModal} />
      </PageTemplate>
      <PageTemplate>
        <QuoteSection toggleModal={toggleModal} />
      </PageTemplate>
      <PageTemplate>
        <VideoSection />
      </PageTemplate>
      <PageTemplate>
        <TestamonialSection toggleModal={toggleModal} />
      </PageTemplate>
      <PageTemplate>
        <ContactSection toggleModal={toggleModal} />
      </PageTemplate>
      <div style={{ height: "60px", width: "90%" }} />
    </div>
  );
};

export default App;
