import React from "react";
import styled from "styled-components";

import OpenQuote from "./quote-elements/OpenQuote";
import CloseQuote from "./quote-elements/CloseQuote";

const CentralQuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuoteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 97px);
  margin-top: 97px;
  position: relative;
`;

const Header = styled.h2`
  font-size: 22px;
  font-weight: 600;
  color: #a4abaf;
  margin: 0;
`;

const Quote = styled.p`
  font-size: 14px;
  width: 630px;
  max-width: 90vw;
  font-weight: 400;
  text-align: justify;
  color: #9ba1a4;
  line-height: 1.5;
  @media only screen and (min-width: 400px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 900px) {
    font-size: 20px;
  }
`;

const Signature = styled.h2`
  font-size: 22px;
  padding-top: 10px;
  font-weight: 600;
  color: #a4abaf;
  align-self: flex-end;
`;

const QuoteSection = () => {
  return (
    <QuoteContainer id="mission">
      <CentralQuoteContainer>
        <OpenQuote />
        <Header>Mission</Header>
        <Quote>
          The goal of the Resilient Life System is to help people who live in
          modern societies lead impeccable lives. We do this by developing the
          mind, brain and body while equipping people with the tools that create
          positive relationships. When these base elements of psychological
          well-being are present, we believe that human potential can thrive.
        </Quote>
        <CloseQuote />
        <Signature>Ricky Gill</Signature>
      </CentralQuoteContainer>
    </QuoteContainer>
  );
};

export default QuoteSection;
