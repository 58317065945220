import React from "react";
import styled from "styled-components";

import SubscribeForm from "./subscribe-modal-elements/SubscribeForm";

const Modal = styled.div`
  background-color: #fff;
  position: fixed;
  min-width: 90vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border: 1px solid #333;
  border-radius: 2px;

  @media only screen and (min-width: 900px) {
    min-width: 75vw;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  color: #222;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
`;

const SubscribeModal = (props) => {
  const { toggleModal } = props;
  return (
    <Modal>
      <CloseButton onClick={toggleModal}>X</CloseButton>
      <SubscribeForm />
    </Modal>
  );
};

export default SubscribeModal;
