import React from "react";
import styled from "styled-components";

import facebookIcon from "../../assets/images/icons/facebook-icon.jpg";
import emailIcon from "../../assets/images/icons/email-icon.jpg";

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

const Icon = styled.img`
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
`;

const openInNewTab = url => {
  const win = window.open(url, "_blank");
  win.focus();
};

const NavMenu = props => {
  const { toggleModal } = props.props;
  return (
    <ButtonsContainer>
      <Icon
        src={facebookIcon}
        onClick={() => openInNewTab("https://www.facebook.com/106728930931140")}
      />
      <Icon onClick={toggleModal} src={emailIcon} />
    </ButtonsContainer>
  );
};

export default NavMenu;
